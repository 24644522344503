<template>
  <div class="gol-tipster-v2">
    <div class="gol-tipster-v2__container">
      <GolTipsterV2 />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GolTipster-v2',
  components: {
    GolTipsterV2: () => import('@/components/Sections/GolTipster-v2/Main'),
  },
};
</script>

<style scoped lang="scss">
.gol-tipster-v2 {
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: 76.5rem;
    margin: 0 0.25rem;
  }
}
</style>
